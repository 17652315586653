import { api } from '@/axios/instance/api';

export const apiApproveOffer = (id, payload) => api.post(`offers/${id}/approve`, payload);
export const apiCancelDocument = (payload) => api.post(`docusign/documents/${payload.id}/cancel_document`);
export const apiCommentOnOffer = (id, payload) => api.post(`offers/${id}/comment`, payload);
export const apiCreateOfferApprovalChain = (payload) => api.post(`offer-approval-chains`, payload);
export const apiCreateOfferApprovalLinks = (payload) => api.post(`offer-approval-links`, payload);
export const apiCreateOfferApprovers = (payload) => api.post(`offer-approvers`, payload);
export const apiDeleteComment = (id) => api.delete(`offers/${id}/delete_comment`);
export const apiDeleteOfferApprovalLink = (id) => api.delete(`offer-approval-links/${id}`);
export const apiDeleteOfferApprover = (id) => api.delete(`offer-approvers/${id}`);
export const apiFetchAllRegions = () => api.get(`regions`);
export const apiFetchOfferApprovalChainData = (originalOfferId) =>
  api.get(`offer-approval-chains?original_offer=${originalOfferId}`);

export const apiFetchOffers = (params) => api.get(`offers`, params);
export const apiFetchSigningDocument = (offerId) => api.get(`docusign/documents?offer__id=${offerId}`);
export const apiGetDocument = (documentId) => api.get(`docusign/documents/${documentId}/view_document`);
export const apiNotifyMentionedUsers = (payload) => api.post(`users/notify-mentioned-user`, payload);
export const apiRejectOffer = (id, payload) => api.post(`offers/${id}/reject`, { comments: payload.comments });
export const apiSendAssignedAnOfferMail = (payload) =>
  api.post(`offer-approvers/${payload.offer_id}/send-assigned-mail`, payload);

export const apiSendDocument = (payload) =>
  api.post(`docusign/documents/send_document`, {
    offer_id: payload.offerId,
    template_id: payload.templateId,
    candidate_signature_info: payload.candidateSignatureInfo,
    company_signers: payload.companySigners,
    watchers: payload.watchers,
  });

export const apiSendEmail = (payload) => api.post(`emails/send-email`, payload);
export const apiUpdateOfferApprovalChain = (payload) => api.patch(`offer-approval-chains/${payload.id}`, payload);
export const apiUpdateOfferApprovalLink = (payload) => api.patch(`offer-approval-links/${payload.id}`, payload);
export const apiUpdateOfferApprover = (payload) => api.patch(`offer-approvers/${payload.id}`, payload);
export const apiUpdateOfferCustomFields = (payload) =>
  api.put(`offers/${payload.id}/update_offer_custom_fields`, payload.data);

export const archiveOffer = (id) => api.patch(`offers/${id}/archive`);
export const fetchOfferApprovalLinkData = (id) => api.get(`offer-approval-links?offer_approval_chain=${id}`);
export const fetchOfferApproverData = (id) => api.get(`offer-approvers?offer_approval_chain=${id}`);
export const fetchUsersList = () => api.get(`users/list`);
